import {
    IMedia,
} from './types';
import {
    ICreateMediaDTO,
} from './types/requests';
import {
    ICreateMediaResponse,
} from './types/responses';

import {
    useState,
} from 'react';

import * as fetches from './fetches';

export function useMedia() {
    const [media, setMedia] = useState<IMedia | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const createMedia = async (body: ICreateMediaDTO): Promise<ICreateMediaResponse> => {
        const res = await fetches.fetchCreateMedia(body);

        if (!res.media || res.error) {
            console.log(res.error);

            return res;
        }

        setMedia(res.media);
        setFile(body.file);

        return res;
    };

    return {
        media,
        file,
        createMedia,
    };
}
