import {
    IProps,
    IRef,
} from './types';
import {
    ICreateMediaDTO,
} from 'entities/medias/types/requests';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    chooseFiles,
    requireHash,
} from 'helpers/functions';

import {
    AUTH_HASH_FIELD_NAME,
    AUTH_PUBLIC_KEY_FIELD_NAME,
} from 'entities/constants';
import {
    useMedia,
} from 'entities/medias/hooks';

import {
    ErrorMessage,
} from 'components/other';

import {
    ERROR,
} from './constants';
import {
    formatError,
} from './functions';

import clipImage from './media/clip.png';

import styles from './AttachmentInput.module.scss';

const AttachmentInput = forwardRef<IRef, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);
    const [isPending, setIsPending] = useState(false);

    const {
        media,
        file,
        createMedia,
    } = useMedia();

    const onUpload = async () => {
        if (isDisabled) {
            return;
        }

        const [file] = await chooseFiles(['image'], false);

        if (!file) {
            return;
        }

        setIsPending(true);

        const body: Omit<ICreateMediaDTO, 'file' | 'hash'> = {
            [AUTH_PUBLIC_KEY_FIELD_NAME]: props.publicKey,
        };
        const hash = await requireHash(body);

        await createMedia({
            ...body,
            file,
            [AUTH_HASH_FIELD_NAME]: hash,
        });
        setIsPending(false);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): string | null {
                return media?.id ?? null;
            },
            validate(): boolean {
                if (!media) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    const isDisabled = props.isDisabled || isPending;

    return (
        <div
            className={
                classNames(styles.attachmentInput, props.className, {
                    [styles.isError]: !!error,
                })
            }
            onClick={onUpload}
        >
            <div className={styles.content}>
                <div className={styles.label}>
                    Click to upload a file
                </div>
                <img
                    className={styles.clipImage}
                    src={clipImage}
                    alt={''}
                />
            </div>
            {
                file &&
                <img
                    className={styles.attachment}
                    src={URL.createObjectURL(file)}
                    alt={''}
                />
            }
            <ErrorMessage text={formatError(error)}/>
        </div>
    );
});

export default AttachmentInput;
