import {
    IPaymentInfo,
} from './types';
import {
    IPostPayInBody,
    IPostPayOutBody,
    IPostSMSBody,
} from './types/requests';
import {
    IPostPayIn,
    IPostPayOut,
    IPostSMS,
} from './types/responses';

import {
    useState,
} from 'react';

import {
    locationReplaceParent,
} from 'helpers/functions';

import * as fetches from './fetches';

export function usePayment() {
    const [paymentId, setPaymentId] = useState<string | null>(null);
    const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo | null>(null);

    const postPayInPayment = async (body: IPostPayInBody): Promise<IPostPayIn> => {
        const res = await fetches.postPayIn(body);

        if (res.formUrl) {
            locationReplaceParent(res.formUrl);

            return res;
        }
        if (res.error) {
            console.log(res.error);

            return res;
        }
        if (res.paymentId) {
            setPaymentId(res.paymentId);
        }
        if (res.info) {
            setPaymentInfo(res.info);
        }

        return res;
    };
    const postPayOutPayment = async (body: IPostPayOutBody): Promise<IPostPayOut> => {
        const res = await fetches.postPayOut(body);

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };
    const postSMS = async (body: IPostSMSBody): Promise<IPostSMS> => {
        const res = await fetches.postSMS(body);

        if (!res.isSucceed || res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    return {
        paymentId,
        paymentInfo,
        postPayInPayment,
        postPayOutPayment,
        postSMS,
    };
}
