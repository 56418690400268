import {
    ICreateMediaDTO,
} from './types/requests';
import {
    ICreateMediaResponse,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    AUTH_HASH_FIELD_NAME,
    AUTH_PUBLIC_KEY_FIELD_NAME,
} from '../constants';

import {
    MEDIAS_ENTITY_PATH,
} from './constants';

export async function fetchCreateMedia(dto: ICreateMediaDTO): Promise<ICreateMediaResponse> {
    const formData = new FormData();

    formData.set(AUTH_PUBLIC_KEY_FIELD_NAME, dto[AUTH_PUBLIC_KEY_FIELD_NAME]);
    formData.set(AUTH_HASH_FIELD_NAME, dto[AUTH_HASH_FIELD_NAME]);
    formData.set('file', dto.file);

    return new Fetch<ICreateMediaResponse>({
        url: `${config.CRM_BACKEND_URL}/${MEDIAS_ENTITY_PATH}/payments`,
        method: 'POST',
        formData,
    })
        .on([200], (body) => {
            return {media: body};
        })
        .on([400, 401, 403, 500], (body) => {
            return body;
        })
        .exec();
}
