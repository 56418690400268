export const ENTITY_PATH = 'payment_methods/v1';

export enum PAYMENT_METHOD_TYPE {
    PAY_IN = 'PAY_IN',
    PAY_OUT = 'PAY_OUT',
}

export enum CLIENT_FIELD_TYPE {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    REGEX = 'REGEX',
    DATETIME = 'DATETIME',
}

export const DEFAULT_LOCALE = 'EN';
