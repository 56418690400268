interface IConfig {
    MODE: 'DEV' | 'REVIEW' | 'TEST' | 'PROD';
    CRM_BACKEND_URL: string;
}

const config: IConfig = {
    MODE: String(process.env.REACT_APP_MODE) as 'DEV' | 'REVIEW' | 'TEST' | 'PROD',
    CRM_BACKEND_URL: String(process.env.REACT_APP_CRM_BACKEND_URL ?? ''),
};

export default config;
